<template>
  <div>
    <h5 class="font-weight-bold">SMS Notification Center</h5>
    <form @submit.prevent="sendMessage">
      <div class="row mt-3">
        <div class="col">
          <div class="card rounded shadow-sm">
            <div class="card-body">
              <div class="form-group">
                <label for="">Template</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="selectedTemplate"
                  @change="getRecipients"
                  required
                >
                  <option value="" disabled selected hidden>Choose</option>
                  <option value="TES">
                    TES
                  </option>
                  <option value="TES_TDP">
                    TES-TDP
                  </option>
                  <option value="CSP">
                    CSP
                  </option>
                  <option value="SIKAP">
                    SIKAP
                  </option>
                  <option value="SALARY_REG">SALARY - REGULAR</option>
                  <option value="SALARY_COS">SALARY - COS</option>
                  <option value="REIMBURSEMENT">
                    REIMBURSEMENT
                  </option>
                  <option value="BENEFITS">
                    BENEFITS
                  </option>
                  <option value="RQAT_DEPOSITED">
                    RQAT - DEPOSITED
                  </option>
                  <option value="RQAT_CHECK_PICK_UP">
                    RQAT - CHECK FOR PICK UP
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="card rounded shadow-sm">
            <div class="card-body">
              <div class="form-group">
                <label for="">Send to</label>
                <Multiselect
                  v-model="recipients"
                  :options="options"
                  track-by="id"
                  :custom-label="nameWithCode"
                  placeholder="Choose"
                  :searchable="true"
                  :multiple="true"
                  :disabled="isDisabled"
                  required
                ></Multiselect>
              </div>

              <div class="form-group" v-show="!isDisabled">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="is_checked"
                    @change="is_checked ? selectAll() : unselectAll()"
                  />
                  <label class="form-check-label" for="gridCheck">
                    Select All
                  </label>
                </div>
              </div>

              <table v-show="recipients.length" class="table table-sm mt-3">
                <thead>
                  <th>HEI</th>
                  <th>TYPE</th>
                  <th>NAME</th>
                  <th>CONTACT NO</th>
                  <th width="20%">AMOUNT</th>
                  <th width="25%">PERIOD</th>
                </thead>
                <tbody>
                  <tr v-for="recipient in recipients" :key="recipient.id">
                    <td>{{ recipient.abbrev }}</td>
                    <td>{{ recipient.account_type }}</td>
                    <td>{{ recipient.name }}</td>
                    <td>{{ recipient.mobile_no }}</td>
                    <td>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="recipient.amount"
                        required
                      />
                    </td>
                    <td>
                      <div class="input-group">
                        <select
                          name=""
                          id=""
                          class="form-control form-control-sm"
                          v-model="recipient.semester"
                          required
                        >
                          <option value="1st Sem">1st Sem</option>
                          <option value="2nd Sem">2nd Sem</option>
                        </select>
                        <select
                          name=""
                          id=""
                          class="form-control form-control-sm"
                          v-model="recipient.academic_year"
                          required
                        >
                          <option value="2022-2023">2022-2023</option>
                          <option value="2021-2022">2021-2022</option>
                          <option value="2020-2021">2020-2021</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card rounded shadow-sm">
            <div class="card-body">
              <div class="form-group">
                <label for="">Message</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="12"
                  class="form-control"
                  v-model="message"
                  disabled
                ></textarea>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <button
              id="send-message-btn"
              class="btn btn-primary"
              @click="sendMessage"
            >
              SEND
            </button>
          </div>

          <div v-if="showLoader" class="mb-2">
            <p class="">
              Please wait. Do not refresh the page. Sending {{ counter }} out of
              {{ recipients.length }}.
            </p>
          </div>
        </div>
        <div class="col">
          <div class="card rounded shadow-sm">
            <div class="card-body">
              <div class="form-inline">
                <div class="form-group">
                  <label for="">From</label>
                  <input type="date" class="form-control mx-2" v-model="from" />
                </div>

                <div class="form-group">
                  <label for="">To</label>
                  <input type="date" class="form-control mx-2" v-model="to" />
                </div>

                <button
                  class="btn btn-primary mx-2"
                  @click.prevent="getSemaphoreMonthlyMessages"
                >
                  FILTER
                </button>
              </div>
            </div>
          </div>

          <div
            class="card rounded shadow-sm overflow-auto"
            style="height: 550px;"
          >
            <div class="card-body">
              <div class="card-title">
                <div class="d-flex justify-content-between">
                  <strong>Recent messages</strong>
                  <a href="#" @click.prevent="getSemaphoreMonthlyMessages"
                    ><i class="bi bi-arrow-clockwise"></i> Refresh</a
                  >
                </div>
              </div>
              <table
                id="recent-messages-table"
                class="table table-sm table-hover"
              >
                <tbody>
                  <tr
                    v-for="message in messages"
                    :key="message.id"
                    @click="openMessageModal(message)"
                  >
                    <td>{{ message.recipient_name }}</td>
                    <td>{{ message.recipient }}</td>
                    <td>{{ message.network }}</td>
                    <td>{{ message.status }}</td>
                    <td>{{ message.updated_at | dateTime }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="modal fade" id="message-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="message">
              {{ form.recipient_name }} - {{ form.recipient }} ({{
                form.network
              }})
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p style="white-space:pre-line">{{ form.message }}</p>
            <small class="text-muted"
              >Sent on: {{ form.updated_at | completeDateTime }}</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { templates } from './MessageTemplates';
import setMessage from './Message';

export default {
  name: 'Main',
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedTemplate: '',
      is_checked: false,
      templates: templates,
      recipients: [],
      showLoader: false,
      isDisabled: true,
      counter: 0,
      messages: [],
      from: '',
      to: '',
      options: [],
      form: new Form({
        recipient_name: '',
        recipient: '',
        message: '',
        network: '',
        updated_at: '',
      }),
    };
  },
  methods: {
    async sendMessage() {
      if (!this.recipients.length) {
        toastr.error('No recipient/s selected.', 'Error');
        return;
      }

      const btn = document.getElementById('send-message-btn');
      btn.innerHTML = 'SENDING';
      btn.disabled = true;
      this.showLoader = true;

      for (const recipient of this.recipients) {
        this.counter++;
        await this.toSempahore(recipient);
      }

      this.getSemaphoreMonthlyMessages();
      this.recipients = [];
      toastr.success(
        `Successfully sent (${this.counter}) message/s.`,
        'Information'
      );
      this.is_checked = false;
      this.counter = 0;
      btn.innerHTML = 'SEND';
      btn.disabled = false;
      this.showLoader = false;
    },

    hasEmptyFields(array) {
      return array.every((obj) => {
        return obj.amount == '' || obj.semester == '' || obj.academic_year == ''
          ? true
          : false;
      });
    },
    toSempahore(recipient) {
      return axios
        .post('api/sms', {
          message: setMessage(this.selectedTemplate, recipient),
          number: recipient.mobile_no,
        })
        .then((res) => {})
        .catch((err) => {
          toastr.error(
            'Unable to send message.',
            'Oops! Something went wrong.'
          );
        });
    },
    getRecipients() {
      this.recipients = [];

      axios
        .get('api/sms', {
          params: {
            account_type: this.selectedTemplate,
          },
        })
        .then((res) => {
          this.options = res.data.map((obj) => ({
            ...obj,
            amount: '',
            semester: '',
            academic_year: '',
          }));
          this.isDisabled = false;
        })
        .catch((err) => {
          toastr.error(
            'Unable to get recipients.',
            'Oops! Something went wrong.'
          );
        });
    },
    getSemaphoreMonthlyMessages() {
      axios
        .get('api/recent-sms', {
          params: {
            from: this.from,
            to: this.to,
          },
        })
        .then((res) => {
          this.messages = res.data.reverse();
        })
        .catch((err) => {});
    },
    openMessageModal(data) {
      $('#message-modal').modal('show');
      this.form.fill(data);
    },
    nameWithCode({ abbrev, name }) {
      return `${abbrev} - ${name}`;
    },
    selectAll() {
      this.recipients = [];
      this.options.forEach((option) => {
        this.recipients.push(option);
      });
    },

    unselectAll() {
      this.recipients = [];
    },
  },
  computed: {
    message() {
      return this.templates[this.selectedTemplate];
    },
  },
  mounted() {
    this.getSemaphoreMonthlyMessages();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
#recent-messages-table tr {
  cursor: pointer !important;
}
</style>
