 export const templates = {
    TES: `Dear [HEI_ABBREVIATION],    
        
Good day! The following TES fund has been deposited to your designated bank account: 
        
Acc No: [HEI_ACCOUNT_NUMBER]
Amount: [AMOUNT]
Period: [SEMESTER] [ACADEMIC YEAR]
        
Please issue the official receipt 5 days upon receiving this message.
        
For concerns, contact us at (085) 817-0930 or thru chedcaraga@ched.gov.ph. Thank you.`,

TES_TDP: `Dear [HEI_ABBREVIATION],    
        
Good day! The following TES-TDP fund has been deposited to your designated bank account: 
        
Acc No: [HEI_ACCOUNT_NUMBER]
Amount: [AMOUNT]
Period: [SEMESTER] [ACADEMIC YEAR]
        
Please issue the official receipt 5 days upon receiving this message.
        
For concerns, contact us at (085) 817-0930 or thru chedcaraga@ched.gov.ph. Thank you.`,
    
    CSP: `Dear [HEI_ABBREVIATION],    
        
Good day! The following CSP fund has been deposited to your designated bank account: 
            
Acc No: [HEI_ACCOUNT_NUMBER]
Amount: [AMOUNT]
Period: [SEMESTER] [ACADEMIC YEAR]
            
Please issue the official receipt 5 days upon receiving this message.
            
For concerns, contact us at (085) 817-0930 or thru chedcaraga@ched.gov.ph. Thank you.`,
    SALARY_REG: `Hi [NAME]!

Good day. We are happy to inform you that your SALARY for this period has been forwarded to LANDBANK. It may take 24 to 48 hours before it reflects in your account. Thank you.

`,
BENEFITS: `Hi [NAME]!

Good day. We are happy to inform you that your BENEFITS were already forwarded to LANDBANK. It may take 24 to 48 hours before it reflects in your account. Thank you.

`,
    SALARY_COS: `Hi [NAME]!

Good day. We are happy to inform you that your SALARY for this period has been forwarded to LANDBANK. It may take 24 to 48 hours before it reflects in your account. Thank you.

`,
    REIMBURSEMENT: `Hi [NAME]!

Good day. We are happy to inform you that your REIMBURSEMENT has been forwarded to LANDBANK. It may take 24 to 48 hours before it reflects in your account. Thank you.

`,
    
    SIKAP: `Dear [NAME],    
        
Good day! The following SIKAP fund has been deposited to your designated bank account: 
                
Acc No: [ACCOUNT_NUMBER] 
Amount: [AMOUNT]
Period: [SEMESTER] [ACADEMIC YEAR]
                                
For concerns, contact us at 0920 876 6879 or thru sikapgrant.caraga@ched.gov.ph. Thank you.`,

RQAT_DEPOSITED: `Hi [NAME]!

Good day. We are happy to inform you that your HONORARIUM has been forwarded to LANDBANK. It may take 24 to 48 hours before it reflects in your account. Thank you.

`,

RQAT_CHECK_PICK_UP: `Hi [NAME]!

Good day. We are pleased to inform you that the check for your HONORARIUM is now ready for pick up. You can claim it at our cashier's office. Please bring any valid ID. Thank you.

`,

};










