import { templates } from './MessageTemplates';

const STRING_TEMPLATES = templates;

function formatStringToCurrency(amount){
    return Number(amount.replace(/,/g, '')).toLocaleString({ 
        style:'currency', 
        currency: 'PHP', 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2
    });
}

class Message {

    constructor(recipient, template){
        this.recipient = recipient;
        this.template = template;
    }
}

class SikapMessage extends Message {

    constructor(recipient, template, str){
        super(recipient, template);
        this.str = str;
     }

     getMessage(){
        return this.setMessage(this.recipient);
     }
    
     setMessage(recipient){
       this.str = this.str.replace('[NAME]', recipient.name);
       this.str = this.str.replace('[ACCOUNT_NUMBER]', '****-**' + recipient.account_no.substr(7));
       this.str = this.str.replace('[AMOUNT]', formatStringToCurrency(recipient.amount));
       this.str = this.str.replace('[SEMESTER] [ACADEMIC YEAR]', recipient.semester + ' ' + recipient.academic_year);
       return this.str;
     }    
}

class TesMessage extends Message {

    constructor(recipient, template, str){
        super(recipient, template);
        this.str = str;
     }

     getMessage(){
        return this.setMessage(this.recipient);
     }
    
     setMessage(recipient){
       this.str = this.str.replace('[HEI_ABBREVIATION]', recipient.abbrev);
       this.str = this.str.replace('[HEI_ACCOUNT_NUMBER]', '****-**' + recipient.account_no.substr(7));
       this.str = this.str.replace('[AMOUNT]', formatStringToCurrency(recipient.amount));
       this.str = this.str.replace('[SEMESTER] [ACADEMIC YEAR]', recipient.semester + ' ' + recipient.academic_year);
       return this.str;
     }    
}

class TesTdpMessage extends Message {

    constructor(recipient, template, str){
        super(recipient, template);
        this.str = str;
     }

     getMessage(){
        return this.setMessage(this.recipient);
     }
    
     setMessage(recipient){
       this.str = this.str.replace('[HEI_ABBREVIATION]', recipient.abbrev);
       this.str = this.str.replace('[HEI_ACCOUNT_NUMBER]', '****-**' + recipient.account_no.substr(7));
       this.str = this.str.replace('[AMOUNT]', formatStringToCurrency(recipient.amount));
       this.str = this.str.replace('[SEMESTER] [ACADEMIC YEAR]', recipient.semester + ' ' + recipient.academic_year);
       return this.str;
     }    
}

class CspMessage extends Message {

    constructor(recipient, template, str){
        super(recipient, template);
        this.str = str;
     }

     getMessage(){
        return this.setMessage(this.recipient);
     }
    
     setMessage(recipient){
       this.str = this.str.replace('[HEI_ABBREVIATION]', recipient.abbrev);
       this.str = this.str.replace('[HEI_ACCOUNT_NUMBER]', '****-**' + recipient.account_no.substr(7));
       this.str = this.str.replace('[AMOUNT]', formatStringToCurrency(recipient.amount));
       this.str = this.str.replace('[SEMESTER] [ACADEMIC YEAR]', recipient.semester + ' ' + recipient.academic_year);
       return this.str;
     }    
}

class ReimbursementMessage extends Message {

    constructor(recipient, template, str){
        super(recipient, template);
        this.str = str;
     }

     getMessage(){
        return this.setMessage(this.recipient);
     }
    
     setMessage(recipient){
       this.str = this.str.replace('[NAME]', recipient.name);
       return this.str;
     }
}

class SalaryMessage extends Message {

    constructor(recipient, template, str){
        super(recipient, template);
        this.str = str;
     }

     getMessage(){
        return this.setMessage(this.recipient);
     }
    
     setMessage(recipient){
       return this.str.replace('[NAME]', recipient.name);       
     }
}

class BenefitsMessage extends Message {

    constructor(recipient, template, str){
        super(recipient, template);
        this.str = str;
     }

     getMessage(){
        return this.setMessage(this.recipient);
     }
    
     setMessage(recipient){
       return this.str.replace('[NAME]', recipient.name);       
     }
}


class RqatDepositedMessage extends Message {

    constructor(recipient, template, str){
        super(recipient, template);
        this.str = str;
     }

     getMessage(){
        return this.setMessage(this.recipient);
     }
    
     setMessage(recipient){
       return this.str.replace('[NAME]', recipient.name);       
     }
}


class RqatCheckPickupMessage extends Message {

    constructor(recipient, template, str){
        super(recipient, template);
        this.str = str;
     }

     getMessage(){
        return this.setMessage(this.recipient);
     }
    
     setMessage(recipient){
       return this.str.replace('[NAME]', recipient.name);       
     }
}

export default function setMessage(template, recipient){

    
    if(template == 'SIKAP') {
       const sikap = new SikapMessage(recipient, template, STRING_TEMPLATES[template]);
       return sikap.getMessage();
    }

    if(template == 'REIMBURSEMENT') {
        const reimbursement = new ReimbursementMessage(recipient, template, STRING_TEMPLATES[template]);
        return reimbursement.getMessage();
    }

    if(template == 'SALARY_REG' || template == 'SALARY_COS'){
        const salary = new SalaryMessage(recipient, template, STRING_TEMPLATES[template]);
        return salary.getMessage();
    }
    
    if(template == 'TES'){
        const tes = new TesMessage(recipient, template, STRING_TEMPLATES[template]);
        return tes.getMessage();
    }

    if(template == 'CSP'){
        const csp = new CspMessage(recipient, template, STRING_TEMPLATES[template]);
        return csp.getMessage();
    }

    if(template == 'BENEFITS'){
        const benefits = new BenefitsMessage(recipient, template, STRING_TEMPLATES[template]);
        return benefits.getMessage();
    }

    if(template == 'TES_TDP'){
        const tes_tdp = new TesTdpMessage(recipient, template, STRING_TEMPLATES[template]);
        return tes_tdp.getMessage();
    }

    if(template == 'RQAT_DEPOSITED'){
        const rqat_deposited = new RqatDepositedMessage(recipient, template, STRING_TEMPLATES[template]);
        return rqat_deposited.getMessage();
    }

    if(template == 'RQAT_CHECK_PICK_UP'){
        const rqat_check_pick_up = new RqatCheckPickupMessage(recipient, template, STRING_TEMPLATES[template]);
        return rqat_check_pick_up.getMessage();
    }

}